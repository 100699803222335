const GoBackIcon = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.42388 9.00035L12.042 4.73481L10.685 3.26562L3.39355 10.0004L10.685 16.7347L12.042 15.2655L7.42401 11.0003L16.8674 11.0003V9.00035L7.42388 9.00035Z"
      fill="currentColor"
    />
  </svg>
);

export default GoBackIcon;
