import { defaultLocale, locales } from "../locales";

export function getLanguage(locale?: string) {
  return locale || defaultLocale;
}

export function getDefaultLocale() {
  return defaultLocale;
}

export function changeLocale(locale: string) {
  let currentPath = window.location.pathname;
  const localePrefix = locales.find((item) => currentPath.startsWith(`/${item}/`) || currentPath === `/${item}`);
  if (localePrefix) {
    currentPath = currentPath.substring(Math.min(localePrefix.length + 1, currentPath.length));
  }
  const newUrl = `${window.location.origin}${locale ? "/" + locale : ""}${currentPath}`;
  window.location.assign(newUrl);
}

export function getLocalePrefix() {
  const currentPath = window.location.pathname;
  const localePrefix = locales.find((locale) => currentPath.startsWith(`/${locale}/`));
  if (localePrefix) {
    return `/${localePrefix}`;
  }

  return "";
}
