import { MakesCount } from "../types/ModelInfo";
import { ActionButtonData } from "../types/site";
import { SearchResponse, Vehicle, VehiclesResponse } from "../types/vehicle";
import { prefetchActionButtonData } from "../utils/actionButtons";
import { getRequestObject, postDataObject } from "./base";
import { getVehicleTag, serverHeaders } from "./site";

export function getUsedMakes(siteId: string): Promise<MakesCount[] | null> {
  const API = `${process.env.SITE_BUILDER_EXTERNAL_PATH}/${siteId}/makes`;

  return getRequestObject<MakesCount[]>(API, { type: "USED,CERTIFIED" }, serverHeaders, [getVehicleTag(siteId)]);
}

export function getVehicle(siteId: string, vehicleId: string): Promise<Vehicle | null> {
  const API = `${process.env.SITE_BUILDER_INTERNAL_PATH}/${siteId}/vehicles/${vehicleId}`;

  return getRequestObject<Vehicle>(API, undefined, serverHeaders, [getVehicleTag(siteId)]);
}

export function getMultipleVehicles(
  siteId: string,
  vehicleIds: string[],
  actionButtons?: ActionButtonData[],
): Promise<Vehicle[]> {
  const API = `${process.env.SITE_BUILDER_EXTERNAL_PATH}/${siteId}/vehicles`;

  return postDataObject(API, vehicleIds).then((res) => {
    if (actionButtons) {
      return prefetchActionButtonData(res, actionButtons).then(() => res);
    }
    return res;
  });
}

export function getVehiclesSimilar(
  vehicleId: string,
  siteId: string,
  fields: string[],
  limit?: number,
): Promise<Vehicle[] | null> {
  let API = `${
    process.env.SITE_BUILDER_INTERNAL_PATH
  }/${siteId}/vehiclesSimilar?vehicleId=${vehicleId}&fields=${fields.join(",")}`;
  if (limit) {
    API = API + `&limit=${limit}`;
  }
  return getRequestObject<Vehicle[]>(API, undefined, serverHeaders, [getVehicleTag(siteId)]);
}

export enum ImageSize {
  BIG = "big",
  MEDIUM = "medium",
  SMALL = "small",
}

export function getSiteVehicles(
  siteId: string,
  params: any,
  actionButtons?: ActionButtonData[],
): Promise<VehiclesResponse | null> {
  const baseUrl = process.env.SITE_BUILDER_INTERNAL_PATH;
  const API = `${baseUrl}/${siteId}/vehicles`;
  return getRequestObject<VehiclesResponse>(API, params, serverHeaders, [getVehicleTag(siteId)]).then((res) => {
    if (res && actionButtons) {
      return prefetchActionButtonData(res.vehicles, actionButtons).then(() => res);
    }
    return res;
  });
}

export function getSearchResponse(siteId: string, text: string, limit: number): Promise<SearchResponse | null> {
  const API = `${process.env.SITE_BUILDER_EXTERNAL_PATH}/${siteId}/search`;
  return getRequestObject<SearchResponse>(API, { text, limit }, serverHeaders, [getVehicleTag(siteId)]);
}
