import {
  Address,
  BlockData,
  ButtonAction,
  GeoLocation,
  PageData,
  ScrollTypeNew,
  CustomPhoneData,
  ClientSiteData,
} from "../types/site";
import { SearchFilter } from "./filters";
import { VehicleInterestType } from "./lead";
import { Vehicle } from "./vehicle";
import { FinanceConditions } from "../types/finance";
import { Background } from "@/ui/common-components/text-component/const";
import { Translations } from "../utils/translations";

export interface SeoData {
  title?: string;
  description?: string;
  keywords?: string;
  ogImage?: string | null;
  ogTitle?: string;
  ogDescrption?: string;
}

export interface NextPageParams {
  params: { [key: string]: string | string[] };
}
export interface NextPageProps extends NextPageParams {
  searchParams: { [key: string]: string | undefined };
}

export interface PageConfigs {
  [configId: string]: BlockData;
}

export interface RequestContext {
  isSmartphone: boolean;
  locale: string;
}
export interface PageContext extends RequestContext {
  path: string;
  host: string;
}

export interface IPageData {
  configs: PageConfigs;
  data?: any;
  host: string;
  page?: PageData;
}

export enum InfoBlockType {
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
  BOTTOM = "bottom",
}

export enum ImageFormat {
  SVG = "svg",
  IMAGE = "image",
  ANY = "any",
}

export interface ImageData {
  url: string;
  mobileUrl?: string;
  format?: ImageFormat;
}

export interface VehiclesPagination {
  totalCount: number;
  pages: {
    [page: number]: Vehicle[];
  };
}

export interface FilterValue {
  filter: string;
  value: string;
}

export interface FiltersInfo {
  filters: SearchFilter[];
  predefinedFilters: FilterValue[];
}

export interface RawFiltersInfo {
  fields: string[];
  predefinedFilterParams: FilterValue[];
}

export interface PriorityValue {
  value: string;
  priority: number;
}
export interface VehicleFieldPriority {
  field: string;
  priorityValues: PriorityValue[];
}

export enum SortOrder {
  DESC = "DESC",
  ASC = "ASC",
}

export interface SortOptionValue {
  field?: string;
  order?: SortOrder;
}

export interface SortOption extends SortOptionValue {
  title: string;
  default?: boolean;
}

export enum SearchType {
  GENERIC = "generic",
  VIN_OR_STOCK = "vin_or_stock",
}

export enum CardTab {
  INFO = "info",
  PRICE = "price",
}

export interface IBaseSearchResultPageData {
  title: string;
  sortPriorities?: VehicleFieldPriority[];
  scrollType?: ScrollTypeNew;
  sortOptions: SortOption[];
  searchType?: SearchType;
  defaultCardTab: CardTab;
}

export interface ISearchResultPageData extends IBaseSearchResultPageData {
  filters: FiltersInfo;
}

export interface IRawSearchResultPageData extends IBaseSearchResultPageData {
  filters: RawFiltersInfo;
}

export interface IFrameConfig {
  url: string;
  width?: number;
  height?: number;
  allowScrolling?: boolean;
  allowNavigation?: boolean;
}

export interface LeadPopupConfig {
  title: string;
  buttonTitle: string;
  disclamer: string;
  leadSource: string;
  interest: VehicleInterestType;
  needVerification: boolean;
  confirmCodeTitle?: string;
  confirmCodeText?: string;
  fieldsToShow?: FilterName[];
  useSiteDealerForLeads: boolean;
  thankYouTitle?: string;
  thankYouText?: string;
}

export interface ISqueezePopupConfig extends LeadPopupConfig {
  textInCorner: string;
}

export interface ITextBlockConfig {
  html: string;
  title?: string;
  background?: Background;
}

export const VEHICLES_PER_PAGE = 12;
export const POPUP_DEFAULT_WIDTH = 940;
export const POPUP_DEFAULT_HEIGHT = 580;
export const BLOG_POSTS_PER_PAGE = 10;

export enum CardView {
  ROW = "row",
  GRID = "grid",
}

export interface IPageBlock {
  config: any;
  siteData: ClientSiteData;
  pageContext: PageContext;
  translations: Translations;
}

export interface IShortDealerInfo {
  name?: string;
  image?: string;
  website?: string;
  address?: Address;
  location?: GeoLocation;
  phone?: string;
}

export interface IDealerInfoCard extends IShortDealerInfo {
  description?: string;
  button: ButtonAction;
  pageContext: PageContext;
}

export interface FilterName {
  filter: string;
  title: string;
}

export interface IPopupInfoBlock {
  currentVehicle?: Vehicle;
  backButtonHandler?: () => void;
  step?: number;
  success?: boolean;
}

export enum SiteTextSize {
  STANDARD = "16px",
  INCREASED = "20px",
}

export enum MonochromeValue {
  STANDARD = "unset",
  GRAYSCALE = "grayscale(1)",
}

export interface FinanceCalculatorConfig {
  disclaimer: string;
  title: string;
  description: string;
  confirmationTitle: string;
  confirmationButtonTitle: string;
  confirmCodeText: string;
  terms: number[];
  skipInputLeadMinutes: number;
  skipRegistration?: boolean;
  financeCondition: FinanceConditions;
  showDeaultApr?: boolean;
}

export interface CustomPhonesData {
  customPhones?: CustomPhoneData[];
  customPhonesCaption?: string;
}

export interface BaseBannerItem {
  title?: string;
  image: ImageData;
  link: string;
  altText: string;
}

export interface BaseBanners<T extends BaseBannerItem = BaseBannerItem> {
  items: T[];
  switchDelay: number;
  height?: number;
}
