import { BlockData, PageData, PageDescription, SiteData } from "../types/site";
import { getRequestObject } from "./base";

export const serverHeaders = {
  Origin: "https://dealerwebsite.runmylease.com",
};

export async function getSiteDataFromHost(host: string, language?: string): Promise<SiteData | null> {
  const API = `${process.env.SITE_BUILDER_INTERNAL_PATH}/`;
  const tags = [host];

  return getRequestObject<SiteData>(
    API,
    {
      dns: host,
      lang: language,
    },
    serverHeaders,
    tags,
  );
}

export function getSiteTagForPages(siteId: string): string {
  return `${siteId}_pages`;
}

export function getPageTag(siteId: string, pagePath: string): string {
  return `${siteId}_page_${pagePath}`;
}

export function getPageTags(siteId: string, pagePath: string): string[] {
  return [getPageTag(siteId, pagePath), getSiteTagForPages(siteId)];
}

export function getPageBlocksTag(siteId: string, pagePath: string): string {
  return `${getPageTag(siteId, pagePath)}_blocks`;
}

export function getPageBlocksTags(siteId: string, pagePath: string): string[] {
  return [getPageBlocksTag(siteId, pagePath), getSiteTagForPages(siteId)];
}

export function getBlocksData(
  siteId: string,
  pagePath: string,
  blockNames: string[],
  language?: string,
): Promise<BlockData[] | null> {
  const API = `${process.env.SITE_BUILDER_INTERNAL_PATH}/${siteId}/blocks`;
  const tags = getPageBlocksTags(siteId, pagePath);
  return getRequestObject<BlockData[]>(API, { blockNames: blockNames.join(","), lang: language }, serverHeaders, tags);
}

export function getPageData(siteId: string, pagePath: string): Promise<PageData | null> {
  const API = `${process.env.SITE_BUILDER_INTERNAL_PATH}/${siteId}/page`;
  return getRequestObject<PageData>(API, { pagePath }, serverHeaders, getPageTags(siteId, pagePath));
}

export function getSiteDynamicPaths(siteId: string): Promise<PageDescription[] | null> {
  const API = `${process.env.SITE_BUILDER_INTERNAL_PATH}/${siteId}/dynamicPaths`;
  return getRequestObject<PageDescription[]>(API, undefined, serverHeaders, [], true);
}

export function getVehicleTag(siteId: string): string {
  return `${siteId}_vehicles`;
}

export function getSiteVehiclePaths(siteId: string): Promise<PageDescription[] | null> {
  const API = `${process.env.SITE_BUILDER_INTERNAL_PATH}/${siteId}/vehiclePaths2`;
  return getRequestObject<PageDescription[]>(API, undefined, serverHeaders, [], true);
}
