function createFinalUrl(url: string, params?: any): string {
  let updatedParams: any;
  if (params) {
    updatedParams = {};
    Object.keys(params).forEach((key) => {
      if (params[key] !== undefined) {
        updatedParams[key] = params[key];
      }
    });
  }
  const queryParamsString = updatedParams ? "?" + new URLSearchParams(updatedParams).toString() : "";
  return url + queryParamsString;
}

const REQUEST_TIMEOUT = 7000;

export async function getRequestObject<T = any>(
  url: string,
  params?: any,
  headers?: any,
  tags?: string[],
  preventCache?: boolean,
): Promise<T | null> {
  const finalUrl = createFinalUrl(url, params);
  const requestOptions: RequestInit = {
    headers,
    next: {
      tags,
    },
    signal: AbortSignal.timeout(REQUEST_TIMEOUT),
  };
  if (preventCache || process.env.NODE_ENV === "development") {
    requestOptions.cache = "no-cache";
    //requestOptions.cache = "force-cache";
  } else {
    requestOptions.cache = "force-cache";
  }
  const response = await fetch(finalUrl, requestOptions);
  if (response.ok) {
    return response.json();
  } else if (response.status === 404) {
    return null;
  }
  throw new Error(`could not load url ${url} params ${JSON.stringify(params)} response code ${response.status}`);
}

export function getRequestString(url: string, params?: any, headers?: any): Promise<string | null> {
  return fetch(createFinalUrl(url, params), {
    headers: headers,
  }).then((response) => {
    if (response.ok) {
      return response.text();
    } else if (response.status === 404) {
      return null;
    }

    throw new Error(`could not load url ${url} params ${JSON.stringify(params)} response code ${response.status}`);
  });
}

export function postDataObject<T = any>(url: string, data: any): Promise<T> {
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error(`could not post url ${url} response code ${response.status}`);
  });
}

export function postDataRaw(url: string, data: any): Promise<ArrayBuffer> {
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  }).then((response) => {
    if (response.ok) {
      return response.arrayBuffer();
    }
    throw new Error(`could not post url ${url} response code ${response.status}`);
  });
}
