import { PopupActionData } from "./site";

export enum PopupType {
  COOKIE = "cookie",
  IFRAME = "iframe",
  GLOBAL_SEARCH = "global_search",
  ACCESSIBILITY_WIDGET = "accessibility_widget",
}

export enum CloseButtonType {
  INTERNAL = "internal",
  EXTERNAL = "external",
  NONE = "none",
}

export interface IFramePopupData extends PopupActionData {
  url: string;
  onMessageCallback?: (data: any) => void;
}
