"use client";

import { useCallback, useEffect, useState } from "react";
import { LS_KEYS } from "../types/constants";

const COMPARES_CHANGED_EVENT = "comparesChanged";
interface ComparesData {
  vehicles: string[];
  date: string;
}

const MAX_COMPARES_STORAGE_TIME = 60 * 24 * 3600 * 1000; // keep favorites 60 days

function loadCompares(): string[] {
  const compares = window.localStorage.getItem(LS_KEYS.compares);
  if (compares) {
    const result: ComparesData = JSON.parse(compares);
    if (!result.date) {
      window.localStorage.removeItem(LS_KEYS.compares);
      return [];
    }
    const date = new Date(result.date);
    if (date.getTime() + MAX_COMPARES_STORAGE_TIME < new Date().getTime()) {
      window.localStorage.removeItem(LS_KEYS.compares);
      return [];
    }

    return result.vehicles || [];
  }

  return [];
}

export function useCompares(): number {
  const [compareVehicles, setCompareVehicles] = useState(0);

  const vehicleChangedCallback = useCallback(() => {
    const loadedCompareVehicles: string[] = loadCompares();
    setCompareVehicles(loadedCompareVehicles.length);
  }, []);

  useEffect(() => {
    const loadedCompareVehicles: string[] = loadCompares();
    if (loadedCompareVehicles.length) {
      setCompareVehicles(loadedCompareVehicles.length);
    }

    window.addEventListener(COMPARES_CHANGED_EVENT, vehicleChangedCallback);

    return () => window.removeEventListener(COMPARES_CHANGED_EVENT, vehicleChangedCallback);
  }, [vehicleChangedCallback]);

  return compareVehicles;
}

export function useCompareVehicles(): string[] {
  const [compareVehicles, setCompareVehicles] = useState<string[]>([]);

  const vehicleChangedCallback = useCallback(() => {
    const loadedCompareVehicles = loadCompares();
    setCompareVehicles(loadedCompareVehicles);
  }, []);

  useEffect(() => {
    const loadedCompareVehicles = loadCompares();
    if (loadedCompareVehicles.length) {
      setCompareVehicles(loadedCompareVehicles);
    }

    window.addEventListener(COMPARES_CHANGED_EVENT, vehicleChangedCallback);

    return () => window.removeEventListener(COMPARES_CHANGED_EVENT, vehicleChangedCallback);
  }, [vehicleChangedCallback]);
  return compareVehicles;
}

export function setCompareVehicle(vehicleId: string) {
  const compareVehicles = loadCompares();
  if (!compareVehicles.includes(vehicleId)) {
    compareVehicles.push(vehicleId);
  }

  saveCompareVehicles(compareVehicles);
}

export function saveCompareVehicles(vehicleIds: string[]) {
  const compareData: ComparesData = {
    vehicles: vehicleIds,
    date: new Date().toISOString(),
  };

  window.localStorage.setItem(LS_KEYS.compares, JSON.stringify(compareData));
  window.dispatchEvent(new CustomEvent(COMPARES_CHANGED_EVENT, { bubbles: false }));
}

export function deleteCompareVehicle(vehicleId: string) {
  const compareVehicles = loadCompares();
  const index = compareVehicles.indexOf(vehicleId);
  if (index !== -1) {
    compareVehicles.splice(index, 1);
    saveCompareVehicles(compareVehicles);
  }
}

export function deleteAllCompares() {
  window.localStorage.removeItem(LS_KEYS.compares);
  window.dispatchEvent(new CustomEvent(COMPARES_CHANGED_EVENT, { bubbles: false }));
}
